<template>
  <div class="p60">
    <h3>Dear {{ $bus.user.name }},</h3>
    <p style="color: #778899" class="c-aaa mt12">
      Thank you for choosing to be a jury member for the
      {{ event.Title }} event.
    </p>
    <p style="color: #778899" class="c-aaa">
      Please grade the following submissions.
    </p>
    <!-- <p style="color: #778899">All grading is autosaved.</p> -->
    <div v-if="eventHas['ranking'] && currentRanking" class="ranking">
      <p class="mt20 bold mb12">Current Ranking</p>
      <p v-for="s in currentRanking" :key="s.id + '-ranking'">
        {{ s.ranking }}. {{ event.submissionPrefix || "Submission" }}
        {{ s.event_submission.id }}
      </p>
    </div>
    <div
      v-if="eventHas['marks'] && !eventHas['ranking'] && marksRanking"
      class="ranking"
    >
      <p class="mt20 bold mb12">Current Ranking</p>
      <p v-for="s in currentRanking" :key="s.id + '-marks'">
        {{ s.ranking }}. {{ event.submissionPrefix || "Submission" }}
        {{ s.event_submission.id }}
      </p>
    </div>
    <div class="mt20 flex wrap">
      <div v-for="s in submissions" :key="s.id" class="w200 p8 submission">
        <p class="bold">
          {{ event.submissionPrefix || "Submission" }}
          {{ s.event_submission.id }}
        </p>
        <p class="mt8">Files:</p>
        <p
          class="mb12"
          v-for="(f, j) in s.event_submission.files || []"
          :key="f.id"
        >
          <a
            class="pointer mt4"
            style="font-size: 11px; cursor: pointer"
            @click="$bus.redirect(f.url, true, false, s.event_submission.id)"
          >
            Link {{ j + 1 }}
          </a>
        </p>

        <span v-if="eventHas['marks']">
          <label for="">Marks</label>
          <div class="flex mt4 mb12">
            <input
              type="text"
              v-model="s.marks"
              @change="update(s.id, 'marks')"
            />
            <a
              @click="
                s.marks = null;
                update(s.id, 'marks');
              "
              class="myauto ml8 clear pointer"
              >X</a
            >
          </div>
        </span>

        <span v-if="eventHas['grades']">
          <label for="">Grade</label>
          <div class="flex mt4 mb12" style="width: 100%">
            <select
              style="width: 100%"
              v-model="s.grade"
              @change="update(s.id, 'grade')"
            >
              <option v-for="g in grades" :key="g" :value="g">{{ g }}</option>
            </select>
            <a
              @click="
                s.grade = null;
                update(s.id, 'grade');
              "
              class="myauto ml8 clear pointer"
              >X</a
            >
          </div>
        </span>

        <span v-if="eventHas['ranking']">
          <label style="display: block" for="">Ranking</label>
          <div class="flex mt4" style="width: 100%">
            <select
              style="width: 100%"
              v-model="s.ranking"
              @change="update(s.id, 'ranking')"
            >
              <option
                v-for="(n, i) in new Array(submissions.length).fill(1)"
                :key="i"
                :value="i + 1"
              >
                {{ i + 1 }}
              </option>
            </select>
            <a
              @click="
                s.ranking = null;
                update(s.id, 'ranking');
              "
              class="myauto ml8 clear pointer"
              >X</a
            >
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Event",
  data() {
    return {
      event: {},
      submissions: [],
      len: 0,
      grades: ["A", "B", "C", "D", "E", "F"],
      roles: undefined,
    };
  },
  mounted() {
    let params = this.$route.params;
    let id = params.id;
    this.$req
      .get(`events/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$bus.token}`,
        },
      })
      .then((r) => {
        this.event = r.data;
      })
      .catch((e) => console.log(e));
    this.$req
      .get(
        `jury-evaluations?event.id=${id}&jury.id=${this.$bus.user.id}&_limit=-1`,
        {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        }
      )
      .then((r) => {
        this.len = this.submissions.length;
        this.submissions = r.data.map((s, i) => {
          s.index = i + 1;
          return s;
        });
      })
      .catch((e) => console.log(e));
  },
  computed: {
    eventHas: function () {
      return {
        grades: (this.event.grading || "").includes("grades"),
        marks: (this.event.grading || "").includes("marks"),
        ranking: (this.event.grading || "").includes("ranking"),
      };
    },
    currentRanking: function () {
      let ranked = this.submissions
        .filter((s) => s.ranking && s.ranking != Infinity)
        .sort((s1, s2) => s1.ranking - s2.ranking);
      return ranked;
    },
    marksRanking: function () {
      let ranked = this.submissions
        .filter((s) => s.marks)
        .sort((s1, s2) => s1.marks - s2.marks);
      return ranked;
    },
  },
  methods: {
    update: function (id, field) {
      let submission = this.submissions.find((s) => s.id == id);
      let obj = {};
      obj[field] = submission[field];
      this.$req
        .put(`jury-evaluations/${id}`, obj, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="css">
.submission {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 12px 20px;
  margin-right: 16px;
  margin-top: 8px;
}
</style>
